body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.screen {
  flex: 1 1 100%; /* Ocupa 100% em telas menores */
  padding: 20px;
  justify-content: center;
  align-items: center;  
}

.screen ul {
  list-style-type: none;
  padding-left: 30px;
}

